.game_title{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.game_description{
    color: #969696;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}