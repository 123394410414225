.text{
    /* display:block; */
    text-align: center;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    margin-bottom: 0;
    font-weight: 600;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;

}