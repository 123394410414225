.page_title{
    display: block;
    background-color: #0A58CA;
    color: #fff;
    border-radius: 50rem;
    padding: 8px 20px 8px 60px;
    position: relative;
    font-size: 1.125rem;
    margin-bottom: 24px;
}
.page_title_circle{
    position: absolute;
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    background: #FE9E44;
    background: linear-gradient(160deg, #FFCC5F 35%, #FE9E44 100%);
    font-size: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}