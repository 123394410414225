.coin{
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
    background-color: #FFCC5F;
    border: 2px solid #FE9E44;
    margin-right: 0.25rem;
}
.score_box{
    background-color: #FFEBD9;
    box-shadow: 0 5px 10px rgba(12,9,42,0.16);
    border-radius: 24px;
    position:relative;
}
.final_score_box{
    position: absolute;
    top:-20%;
    background-color: #FFCC5F;
    color: #fff;
    padding: 8px 16px;
    border-radius: 8px;
    left: 35%;
}