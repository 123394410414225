.points_box{
    font-size: 0.875rem;
    margin-bottom: 0;
    color: #969696;
    background-color: rgba(223,223,223,0.5);
    border-radius: 50rem;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    display: inline-flex;
    align-items: center;
}
.coin{
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
    background-color: #FFCC5F;
    border: 2px solid #FE9E44;
    margin-right: 0.25rem;
}