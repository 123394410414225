.box_sub_container{
    margin: 0 33%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
@media only screen and (max-width: 900px) {
    .box_sub_container{
        margin: 0 5%;
    }
  }